import { Col, Container, Row } from "react-bootstrap";
import "./ChatBox.scss";
import {
  Dropdown,
  TextInput,
  Button,
  Accordion,
  AccordionItem,
} from "@carbon/react";
import { Send } from "@carbon/icons-react";
import { useState, useRef, useEffect } from "react";
import { SSE } from "sse.js";

import { setDomain, streamResponse, bamHealthCheck } from "../util/api";
import parse from "html-react-parser";
import FeedbackThumbsUpDown from "./FeedbackThumbsUpDown";
import domainList from "../constants/domainList";
import domainList_demo from "../constants/domainList-demo";
import domainList_prod from "../constants/domainList-prod";

import FeedbackModal from "./FeedbackModal";
import { InlineNotification } from "@carbon/react";
import { Loading } from "@carbon/react";

//ChatBox component handles logic and rendering of the messages, domain selection and user input
export function ChatBox(props) {
  const messagesEndRef = useRef(null);
  const resultRef = useRef(); //reference value for current streaming response
  const [selectedDomain, setSelectedDomain] = useState("");
  const [isEmptyString, setIsEmptyString] = useState(true);
  const [isReasonSelected, setIsReasonSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBamHealthCheck, setBamHealthCheck] = useState(true); //false is when there are no problems, changed to true as BAM is sunset

  const feedbackDefault = {
    questionId: "",
    isHelpful: "",
    reason: "",
    comment: "",
    boxlink: "",
  };
  const [feedback, setFeedback] = useState(feedbackDefault);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (
      typeof props.items[props.items.length - 1] !== "undefined" &&
      typeof props.items[props.items.length - 1].answer !== "undefined"
    ) {
      resultRef.current = props.items[props.items.length - 1].answer;
    }

    scrollToBottom();
  }, [props.items]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (props.isThereInput) {
      getGeneratedText(props.input);

      props.setInput(""); //resets input for next question
      props.setIsThereInput(false); //disables the sidebar buttons from being clickable
    }
  }, [props.isThereInput]);

  //Displays a banner in the case that there is a bam error. When the response is not 200.
  useEffect(() => {
    const checkBamHealth = async () => {
      try {
        const response = await bamHealthCheck.seek();
        if (response.status !== 200) {
          setBamHealthCheck(true);
        }
      } catch (error) {
        console.error(
          "An error occurred when setting the Bam Health Check Banner",
          error
        );
      }
    };
    checkBamHealth();
  }, []);

  //Imports the specified sample questions related to the selected domain
  const importQuestionFile = async (fileName) => {
    try {
      const module = await import(
        `../constants/SideBarQuestions/${fileName}.js`
      );
      return module.default;
    } catch (error) {
      console.error(`Error loading ${fileName}:`, error);
    }
  };

  //Populates the domains in the dropdown dynamically based on the file
  let dropdownItemsDomain_tmp = domainList.map((domain, index) => ({
    id: `option-${index + 1}`,
    text: domain,
  }));
  if (process.env.REACT_APP_GENAIRFP_ENV == "prod") {
    dropdownItemsDomain_tmp = domainList_prod.map((domain, index) => ({
      id: `option-${index + 1}`,
      text: domain,
    }));
  } else if (process.env.REACT_APP_GENAIRFP_ENV == "demo") {
    dropdownItemsDomain_tmp = domainList_demo.map((domain, index) => ({
      id: `option-${index + 1}`,
      text: domain,
    }));
  }
  const dropdownItemsDomain = dropdownItemsDomain_tmp;

  const handleDomainClick = async (domain) => {
    setSelectedDomain(domain);
    const questions = await importQuestionFile(`${domain}`);
    if (questions) {
      props.setCurrentDomainQuestion(questions);
    }

    try {
      const response = await setDomain.seek(domain, props.sessionId);

      if (!response.ok || !response.body) {
        writeInResponseMessage(
          "\nError setting the domain. Please refresh page and try again. If the error persists, please try contacting the RFP advisor team for support."
        );
        throw response.statusText;
      }

      props.setIsDomainSet(false);

      var itemDomain = {
        type: "out",
        data: domain,
        source: "",
      };

      let itemResponseDomain = {
        type: "in",
        answer:
          "Thank you. Retrieving " +
          domain +
          " documents... \n\nI am ready for your " +
          domain +
          " question! Remember, for me to better understand your needs try to be descriptive:",
        questionId: "",
        sources: [],
        feedback: "",
        isFeedBckVisible: false,
        isLLMResponse: false,
      };

      props.setItems([...props.items, itemDomain]);
      setTimeout(() => {
        props.setItems([...props.items, itemDomain, itemResponseDomain]);
      }, 600); //Artifical delay added to the reply message, so it feels more
    } catch (error) {
      console.error("An error occurred when setting the domain:", error);
    }
  };

  function handleChangeTextInput(e) {
    if (e.target.value.trimStart() === "") {
      setIsEmptyString(true);
    } else {
      setIsEmptyString(false);
    }
    props.setInput(e.target.value);
  }

  function handleClickTextInput() {
    if (props.input !== "") {
      var item = {
        type: "out",
        data: props.input.trim(),
        source: "",
      };
      props.setItems([...props.items, item]);
      props.setIsThereInput(true);
    }
    scrollToBottom();
  }

  function handleKeyPressTextInput(e) {
    if (e.key === "Enter") {
      handleClickTextInput();
    }
  }

  //set the class for the send button itself
  const currentButtonClassName =
    isEmptyString || props.loading
      ? "button-send-disabled"
      : "button-send-enabled";

  //sets the class for the Send button icon
  const iconClassName =
    isEmptyString || props.loading ? "icon-disabled" : "icon-enabled";

  const getGeneratedText = async (searchValue) => {
    setIsLoading(true);
    props.setLoading(true);
    var sources = [];

    resultRef.current = "";
    let tempId;

    if (props.input !== "") {
      var responseStream = streamResponse(
        searchValue,
        props.sessionId,
        props.userId
      );

      responseStream.addEventListener("error", (e) => {
        writeInResponseMessage(
          "A server error has occurred. Please refresh page and try again. If the error persists, please try contacting the RFP advisor team for support."
        );
        props.setLoading(false);
        setIsLoading(false);

        if (responseStream.readyState !== SSE.CLOSED) {
          responseStream.close();
        }
      });

      responseStream.addEventListener("min_docs_error", (e) => {
        writeInResponseMessage(
          "Based on my knowledge base I was unable to find relevant content to your question. Please try rephrasing your question and try again."
        );
        props.setLoading(false);
        setIsLoading(false);

        if (responseStream.readyState !== SSE.CLOSED) {
          responseStream.close();
        }
      });

      responseStream.addEventListener("bam_error", (e) => {
        writeInResponseMessage(
          "An error generating the response has occurred. Please refresh page and try again."
        );
        props.setLoading(false);
        setIsLoading(false);

        if (responseStream.readyState !== SSE.CLOSED) {
          responseStream.close();
        }
      });
      responseStream.addEventListener("prompt_length_error", (e) => {
        writeInResponseMessage(
          "An error generating the response has occurred. The relevant content generated for this question was too broad. Please rephrase your question and try again."
        );
        props.setLoading(false);
        setIsLoading(false);

        if (responseStream.readyState !== SSE.CLOSED) {
          responseStream.close();
        }
      });

      responseStream.addEventListener("end", (e) => {
        props.setLoading(false);
        setIsLoading(false);
      });

      responseStream.addEventListener("metadata", (e) => {
        let jsonData = JSON.parse(e.data);
        tempId = jsonData.pop();

        jsonData.sort((a, b) => b.doc_avg_score - a.doc_avg_score); //sort in descending order

        jsonData.forEach((doc) => {
          const combinedContents = doc.doc_contents.join("\n\n");
          sources.push({
            content: combinedContents,
            title: doc.doc_filename,
            url: doc.doc_url,
            doc_avg_score: Math.round(doc.doc_avg_score * 100),
          });
        });
      });

      let isFirstChunk = true;
      responseStream.addEventListener("message", (e) => {
        let streamedText = JSON.parse(e.data);
        if (isFirstChunk && streamedText !== "") {
          streamedText = streamedText.trimStart();
          isFirstChunk = false;
        }

        var item = {
          id: tempId,
          type: "in",
          questionId: tempId,
          sources: sources,
          feedback: "none",
          isFeedBckVisible: true,
          isLLMResponse: true,
          answer: "",
        };

        if (streamedText !== "") {
          resultRef.current = resultRef.current + streamedText;
          item.answer = resultRef.current;
          checkLastItemIsInMessageAddSetItem(item);
        }
      });
    }
  };

  //Helper to write out a simple reply message
  function writeInResponseMessage(messageToDisplay) {
    var item = {
      id: "",
      type: "in",
      answer: messageToDisplay,
      questionId: "",
      sources: [],
      feedback: "none",
      isFeedBckVisible: false,
      isLLMResponse: false,
    };

    props.setItems([...props.items, item]);
  }

  function checkLastItemIsInMessageAddSetItem(item) {
    if (props.items[props.items.length - 1].type !== "in") {
      props.setItems([...props.items, item]);
    } else {
      let itemsList = props.items;
      itemsList[itemsList.length - 1].answer = resultRef.current;
      props.setItems(itemsList);
    }
  }
  return (
    <>
      <div className="show-chatbot">
        <div className="chatbot">
          <ul className="chatbox">
            {isBamHealthCheck && (
              <InlineNotification
                hideCloseButton={false}
                aria-label="closes notification"
                kind="warning"
                onClose={function noRefCheck() {}}
                onCloseButtonClick={function noRefCheck() {}}
                statusIconDescription="notification"
                subtitle="The RFP Advisor is currently unavailable due to the sunset of BAM and the absence of an account for migration to WatsonX. As an alternative, it has been integrated with the global tool Consulting advantage. Try with the RFP version on the IBM Consulting advantage portal by the name: IBM Intelligent Request for Proposal Request Generator. You can explore it here: https://servicesessentials.ibm.com/launchpad"
                title="BAM Sunset"
                style={{ marginLeft: "65px" }}
              />
            )}
            {props.items.map((item, index) => {
              const isLastItem = index === props.items.length - 1;

              if (item.type === "in") {
                return (
                  <li className="chat incoming">
                    <div
                      className="placeholder"
                      style={{ marginLeft: "-10px" }}
                    ></div>
                    <Col>
                      <Container
                        style={{
                          backgroundColor: "#F2F3F2",
                          borderRadius: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            {item.isLLMResponse && (
                              <p style={{ fontWeight: 600 }}>
                                After reading through the {selectedDomain}{" "}
                                documents, the answer to your question is:
                              </p>
                            )}
                            <p>{parse(item.answer)}</p>
                          </Col>
                        </Row>

                        <Row id="box">
                          <Col md={12}>
                            <Container>
                              <Row>
                                {item.isLLMResponse &&
                                  !(isLastItem && props.loading) && (
                                    <p style={{ fontWeight: 600 }}>
                                      Sources I referenced:
                                    </p>
                                  )}
                                {item.isLLMResponse &&
                                  !(isLastItem && props.loading) && (
                                    <>
                                      {item.sources.map((source, index) => (
                                        <Col md={12} key={index}>
                                          <Accordion className="source-accordion">
                                            <AccordionItem
                                              className="source-accordion-item"
                                              title={
                                                <>
                                                  <a
                                                    className="source-accordion-title"
                                                    href={source.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {source.title}
                                                  </a>
                                                  {` Relevance: ${source.doc_avg_score}%`}
                                                </>
                                              }
                                            >
                                              <p>{source.content}</p>
                                            </AccordionItem>
                                          </Accordion>
                                        </Col>
                                      ))}
                                    </>
                                  )}
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                      {item.isFeedBckVisible &&
                      !(isLastItem && props.loading) ? (
                        <>
                          <p
                            style={{
                              marginLeft: "20px",
                              marginBottom: "-20px",
                            }}
                          >
                            Was this answer helpful? This is your chance to tell
                            me how I am doing!
                          </p>

                          <FeedbackThumbsUpDown
                            item={item}
                            setShowModal={setShowModal}
                            setFeedback={setFeedback}
                            setItems={props.setItems}
                            items={props.items}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </li>
                );
              } else if (item.type === "out" && isLastItem) {
                return (
                  <li className="chat outgoing">
                    <p style={{ marginRight: "10px" }}>{item.data}</p>
                  </li>
                );
              } else if (item.type === "out") {
                return (
                  <li className="chat outgoing">
                    <p style={{ marginRight: "10px" }}>{item.data}</p>
                  </li>
                );
              } else if (item.type === "first") {
                return (
                  <li className="chat incoming">
                    <div className="placeholder"></div>
                    <p style={{ marginLeft: "10px" }}>{item.answer}</p>
                  </li>
                );
              } else if (item.type === "second") {
                return (
                  <li className="chat incoming">
                    <div className="placeholder"></div>
                    <p style={{ marginLeft: "10px" }}>{item.answer}</p>
                  </li>
                );
              } else if (item.type === "third") {
                return (
                  <ul>
                    <li className="chat incoming">
                      <div className="placeholder"></div>
                      <p style={{ marginLeft: "10px" }}>{item.answer}</p>
                    </li>
                    <li className="domain-container">
                      <Dropdown
                        className="reason-dropdown"
                        id="domain-type"
                        disabled={!props.isDomainSet}
                        titleText="Domain"
                        label="Select a domain"
                        itemToString={(item) => (item ? item.text : "")}
                        items={dropdownItemsDomain}
                        onChange={({ selectedItem }) =>
                          selectedItem.text !== ""
                            ? handleDomainClick(selectedItem.text)
                            : ""
                        }
                      />
                    </li>
                  </ul>
                );
              }
            })}
            <div ref={messagesEndRef}></div>
          </ul>
          {isLoading && (
            <div className="loading-text">
              <div>Generating...</div>
              <div style={{ paddingLeft: "1em" }}>
                <Loading
                  small={true}
                  description="Active loading indicator"
                  withOverlay={false}
                />
              </div>
            </div>
          )}
          <div
            className="chat-input"
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
            }}
          >
            <TextInput
              id="chat-input"
              onChange={handleChangeTextInput}
              value={props.input}
              placeholder="Type your question. Be as specific as you can for the best responses"
              spellCheck={false}
              disabled={props.isDomainSet || props.loading}
              style={{
                marginLeft: "65px",
                background: "white",
                height: "50px",
                fontSize: "16px",
              }}
              onKeyUp={handleKeyPressTextInput}
            />
            <Button
              kind="ghost"
              disabled={props.isDomainSet || isEmptyString || props.loading}
              className={currentButtonClassName}
              renderIcon={() => <Send className={iconClassName} size={24} />}
              onClick={handleClickTextInput}
            ></Button>
          </div>
        </div>
      </div>
      <FeedbackModal
        showModal={showModal}
        feedback={feedback}
        isReasonSelected={isReasonSelected}
        setFeedback={setFeedback}
        setIsReasonSelected={setIsReasonSelected}
        setShowModal={setShowModal}
      />
    </>
  );
}
